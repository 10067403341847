import './App.css';
import logo from "./anasound.webp"

function App() {
  return (
    <div className="App">
      <img src={logo} alt="Ana Sound" />
      <p>Website în construcție...</p>
      <p>În scurt timp veți putea vizualiza noul <span className='bold'>site-magazin</span> Ana Sound România!</p>
    </div>
  );
}

export default App;
